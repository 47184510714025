:root {
  --primary-color: #c1242b;
}

.container-fluid{
  --bs-gutter-x: 0;
}

body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
  background-color: #F5F8FB;
  font-family: 'Roboto', sans-serif;
/* font-family: 'Tenor Sans', sans-serif; */

  color: #262b40;
}

html {
  height: -webkit-fill-available;
}

main {
  display: flex;
  flex-wrap: nowrap;
  height: 100vh;
  height: -webkit-fill-available;
  max-height: 100vh;
  overflow-x: auto;
  overflow-y: auto;
}

.sidebar {
  width: 240px;
  height: 90vh;
  flex: 0 0 200px;
}

.main-content{
  flex: 1;
  overflow-x: auto;
  overflow-y: auto;
}

@media (max-width: 767.98px){
  .offcanvas-md.offcanvas-end {
    width: 240px !important;
  }
}

.navbar-brand{
  width: 240px !important;
  background: #ffffff;
}

@media (max-width: 985px) {
  .bg-white-navbar{
    background-color: #fff;
  }
}

.sidebar a {
  color: rgb(36, 36, 36);
}

.navbar-nav .username,
.navbar-nav .dropdown-item {
  color: var(--primary-color) !important;
  font-weight: 500;
  cursor: pointer;
}

.input-group-text{
  background-color: white;
  border-right: none;
}

input:focus {
  border-color: rgb(211, 209, 209) !important;
  box-shadow: 0 0 0 0.2rem rgba(184, 184, 184, 0.25) !important;
}
select:focus{
  border-color: rgb(211, 209, 209) !important;
  box-shadow: 0 0 0 0.2rem rgba(184, 184, 184, 0.25) !important;
}

.search {
  border-left: none;    
}

.dropdown-toggle { outline: 0; }

.scrollarea {
  overflow-y: auto;
}

.fw-semibold { font-weight: 600; }
.lh-tight { line-height: 1.25; }

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
  background-color: var(--primary-color) !important;
  color: #fff !important;
}


h1 {
  font-size: 32px;
  font-weight: 500;
  line-height: 36px;
}

h2 {
  font-size: 28px;
  font-weight: 500;
  line-height: 36px;
}

h3 {
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
}

.auth-form {
  border-radius: 5px;
  margin-top: 5% !important;
  margin: auto;
  width: 40%;
  padding: 20px 40px;
  -webkit-box-shadow: 10px 10px 51px -15px rgba(185,192,199,1);
  -moz-box-shadow: 10px 10px 51px -15px rgba(185,192,199,1);
  box-shadow: 10px 10px 51px -15px rgba(185,192,199,1);
}

@media (max-width: 767px) {
  .auth-form{
    width:80% !important;
  }
}

.auth-login {
  margin-top: 10% !important;
}

.auth-form .lostpassword {
  font-size: 14px !important;
}

.password-icon-container {
  margin-right: 30px !important;
  transform: translateY(-103%)!important;
}

.btn-wh {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

table {
  background-color: #fff;
}

.table-primary-head {
  background-color: var(--primary-color);
  color: #fff !important;
}

.users .dropdown-toggle::after {
  display: none;
}

.users .table-responsive {
  overflow-x: revert !important;
}

.c-textarea {
  height: 150px !important;
}

.user-img {
  border-radius: 50% !important;
}

.card {
  border-radius: 17px !important;
  border-color: #ffffff !important;
}

.revert-form-control {
  border-color: #fff !important;
}

.revert-form-control:focus {
  border-color: #fff !important;
  box-shadow: none !important;
}

.profile-header {
  background: #F8FAFB;
  padding: 5px;
  border-radius: 5px;
}

.image-uploader p {
  font-size: 13px;
  line-height: 16px;
}

.image-uploader .img-fluid:hover {
  cursor: pointer !important;
}

.loader {
  position: absolute;
  border: 6px solid #f3f3f3; /* Light grey */
  border-top: 16px solid var(--primary-color); /* Dark Green */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spinloader 2s linear infinite;
  top: 50%;
  left: 50%;
}
.loader img{
  height : 120px;
  width : 120px;
  animation: spinlogo 2s linear infinite;
}
@keyframes spinloader {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
@keyframes spinlogo {
  0% { transform: rotate(360deg); }
  100% { transform: rotate(0deg); }
}
.w-110{
  width: 120px !important;
}